<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <commonleft></commonleft>
        <div id="asiderBar">
            <el-menu
                    class="el-menu-vertical-demo"
                    text-color="black"
                    active-text-color="#fff"
                    :collapse-transition="false"
                    :router="true"
                    :default-active="$route.path"
            >
                <el-submenu index="/monitor">
                    <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>物联感知</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="/monitor">
                            <i class="el-icon-s-custom"></i>
                            <span>视频类设备</span>
                        </el-menu-item>
                        <el-menu-item index="/monitorPerception">
                            <i class="el-icon-s-custom"></i>
                            <span>感知类设备</span>
                        </el-menu-item>
                        <el-menu-item index="/monitorInteractive">
                            <i class="el-icon-s-custom"></i>
                            <span>交互类设备</span>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </div>
        <div class="mainpart">
            <el-card id="box-card">
                <div class="title">
                    <el-button @click="addDevice"><span>添加设备</span></el-button>
                </div>
                <div style="display: flex; justify-content: space-between">
                    <div class="areaTable">
                        <el-table
                                :data="tableData"
                                height="100%"
                                :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
                                style="width: 100%; background-color: #142245"
                        >
                            <el-table-column
                                    prop="deviceName"
                                    align="center"
                                    label="设备名称"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.deviceName}}
                                </template>
                            </el-table-column>

                            <el-table-column
                                    prop="deviceSerial"
                                    align="center"
                                    label="设备序列号"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.deviceSerial}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="description"
                                    align="center"
                                    label="设备描述"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.description}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="intervalTime"
                                    align="center"
                                    label="设备健康检测间隔时间"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.intervalTime}}
                                </template>
                            </el-table-column>

                            <el-table-column
                                    prop="maxThreshold"
                                    align="center"
                                    label="最大阈值"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.maxThreshold}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="threshold"
                                    align="center"
                                    label="阈值"
                                    width="150"
                            >
                                <template v-slot="scope">
                                    {{scope.row.threshold }}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template v-slot="scope">
                                    <el-tooltip
                                            effect="dark"
                                            content="查看设备"
                                            placement="top"
                                            :enterable="true"
                                    >
                                        <el-button
                                                type="primary"
                                                icon="el-icon-view"
                                                size="mini"
                                                @click="getRainInfo(scope.row.deviceSerial)"
                                        ></el-button>
                                    </el-tooltip>
                                    <el-tooltip
                                            effect="dark"
                                            content="修改设备"
                                            placement="top"
                                            :enterable="true"
                                    >
                                        <el-button
                                                type="primary"
                                                icon="el-icon-edit"
                                                size="mini"
                                                @click="beforeEdit(scope.row.id)"
                                        ></el-button>
                                    </el-tooltip>
                                    <el-tooltip
                                            effect="dark"
                                            content="删除设备"
                                            placement="top"
                                            :enterable="false"
                                    >
                                        <el-button
                                                type="primary"
                                                icon="el-icon-delete"
                                                size="mini"
                                                @click="deletePerceptionDevice(scope.row.id)"
                                        ></el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-card>
        </div>
        <!-- 添加设备 -->
        <el-dialog
                title="添加监控设备"
                :visible.sync="dialogVisible"
                width="30%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form
                    ref="addFormRef"
                    :model="addForm"
                    label-width="120px"
                    :rules="addFormRule"
            >
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input size="medium" v-model="addForm.deviceName"></el-input>
                </el-form-item>
                <el-form-item label="设备序列号" prop="deviceSerial">
                    <el-input size="medium" v-model="addForm.deviceSerial"></el-input>
                </el-form-item>
                <el-form-item label="设备描述" prop="description">
                    <el-input size="medium" v-model="addForm.description"></el-input>
                </el-form-item>
                <el-form-item label="设备健康检测间隔时间" prop="intervalTime">
                    <el-input size="medium" v-model="addForm.intervalTime"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
        <el-button
                size="medium"
                type="goon"
                round
                @click="dialogVisible = false"
        >取 消</el-button
        >
        <el-button size="medium" type="goon" round @click="addPerceptionDevice"
        >确 定</el-button
        >
      </span>
        </el-dialog>
        <!-- 查看感知设备详情 -->
        <el-dialog
                class="eld1"
                title="查看感知设备记录详情"
                :visible.sync="dialogVisibleRainInfo"
                width="50%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <div class="block" style="margin: -10px 0 10px 0; text-align: center">
                <span class="demonstration"></span>
                <el-date-picker
                        v-model="selectedDate"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder="选择日期"
                        @change="handleMonthChange"
                >
                </el-date-picker>
            </div>
            <el-table
                    :data="rainInfo"
                    height="100%"
                    :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
                    style="width: 100%; background-color: #142245"
            >
                <el-table-column prop="year" align="center" label="年份" width="180">
                    <template #defalult="scope">
                        {{ scope.row.year }}
                    </template>
                </el-table-column>
                <el-table-column prop="month" align="center" label="月份" width="180">
                    <template #defalult="scope">
                        {{ scope.row.month }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="rainFall"
                        align="center"
                        label="累计雨量"
                        width="250"
                >
                    <template #defalult="scope">
                        {{ scope.row.rainFull }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="pushTime"
                        align="center"
                        label="推送时间"
                        width="350"
                >
                    <template #defalult="scope">
                        {{ scope.row.pushTime }}
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryPage.pageNum"
                    :page-size="queryPage.pageSize"
                    layout="total, prev, pager, next,jumper"
                    :total="total"
            >
            </el-pagination>
            <!-- <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="10"
                :page-sizes="[10, 20, 30, 40, 50, 100]">
            </el-pagination> -->
        </el-dialog>
        <!-- 修改感知设备 -->
        <el-dialog title="修改感知设备"
                   :visible.sync="dialogVisibleEdit"
                   width="25%"
                   center
                   :close-on-click-modal="false"
                   @close="dialogClose">
            <el-form ref="addFormRef"
                     :model="editForm"
                     label-width="100px"
                     :rules="addFormRule">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input size="medium" v-model="editForm.deviceName"></el-input>
                </el-form-item>
                <el-form-item label="设备序列号" prop="deviceSerial">
                    <el-input size="medium" v-model="editForm.deviceSerial"></el-input>
                </el-form-item>
                <el-form-item label="设备描述" prop="description">
                    <el-input size="medium" v-model="editForm.description"></el-input>
                </el-form-item>
                <el-form-item label="设备健康检测间隔时间" prop="intervalTime">
                    <el-input size="medium" v-model="editForm.intervalTime"></el-input>
                </el-form-item>
                <el-form-item label="最大阈值" prop="maxThreshold">
                    <el-input size="medium" v-model="editForm.maxThreshold"></el-input>
                </el-form-item>
                <el-form-item label="阈值" prop="maxThreshold">
                    <el-input size="medium" v-model="editForm.Threshold"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="medium" type="goon" round @click="dialogVisibleEdit = false">取 消</el-button>
                <el-button size="medium" type="goon" round @click="editPerceptionDevice">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import commonleft from "../commonleft.vue";

    export default {
        name: "monitorInteractive",
        data() {
            return {
                //设备类型
                deviceType: "perception_device",
                data: [],
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                tableData: [],
                dialogVisible: false,
                addForm: {
                    deviceName: "",
                    deviceSerial: "",
                    deviceType: "perception_device",
                    description: "",
                    intervalTime: "",
                    maxThreshold: "",
                    threshold: "",
                    validateCode: "",
                    projectId: this.$root.proId,
                    x: "",
                    y: "",
                },
                addFormRule: {
                    deviceSerial: [
                        {
                            required: true,
                            message: "请输入设备序列号,存在英文字母的设备序列号，字母需为大写",
                            trigger: "blur",
                        },
                    ],
                },
                dialogVisibleEdit: false,
                editForm: {},
                dialogVisibleRainInfo: false,
                rainInfo: [],
                total: 0,
                queryPage: {
                    deviceId: null,
                    year: null,
                    month: null,
                    pageNum: 1,
                    pageSize: "10",
                },
                selectedDate: null,
            };
        },
        props: {
            msg: String,
        },
        methods: {
            //初始化获取项目下的监控设备列表
            async getDeviceList() {
                this.addForm.projectId = this.$root.proId;
                const {data: result} = await this.$http4.get(
                    "/findAllByProjectId?projectId=" +
                    this.$root.proId +
                    "&deviceType=perception_device"
                );
                if (result.businessCode == "3000") {
                    this.tableData = result.data;
                } else {
                    this.tableData = [];
                    this.$message.error(result.message);
                }
            },
            async getRainInfo(id) {
                this.queryPage.deviceId = id;
                // this.queryPage.year = year;
                // this.queryPage.month = month;
                // this.queryPage.pageNum = 1;
                // this.queryPage.pageSize = 10;
                const {data: result} = await this.$http4.post(
                    "/rain/fall/query/findPageList",
                    this.queryPage
                );

                if (result.businessCode == "3000") {
                    this.rainInfo = result.data.list;
                    this.total = result.data.total;
                    this.dialogVisibleRainInfo = true;
                } else {
                    this.rainInfo = [];
                    this.$message.error(result.message);
                }
            },

            handleCurrentChange(pageNum) {
                // console.log('当前页: '+val);
                // this.rainInfo = [];
                // this.queryPage.pageNum = pageNum;
                this.getRainInfo();

                // this.getDataList(val,this.pSize);
            },
            handleSizeChange(pageSize) {
                // console.log('每页'+ val+' 条');
                // this.rainInfo = [];
                // this.queryPage.pageSize = pageSize;
                this.getRainInfo();
                //this.pSize = val;
                //this.getDataList(1, val);
            },
            handleMonthChange(value) {
                // this.rainInfo = [];
                // this.getRainInfo();
                if (value) {
                    // this.rainInfo = [];
                    const [year, month] = value.split("-");
                    this.getRainInfo();
                }
                // else{
                //   this.rainInfo = [];
                // }
            },
            addDevice() {
                this.dialogVisible = true;
            },
            async addPerceptionDevice() {
                //默认参数
                this.addForm.projectId = this.$root.proId;
                this.addForm.deviceType = "perception_device";
                //调用接口添加
                const {data: res} = await this.$http4.post("/addDevice", this.addForm);
                if (res.businessCode == "4000") {
                    this.getDeviceList();
                    this.dialogVisible = false;
                } else {
                    return this.$message.error(res.message + "!" + res.data);
                }
            },
            async beforeEdit(id) {
                const {data: res} = await this.$http4.get("/findById?id=" + id);
                if (res.businessCode == "3000") {
                    this.editForm = res.data;
                    this.dialogVisibleEdit = true;
                } else {
                    this.$message.error(res.message);
                }
            },
            async editPerceptionDevice() {
                this.editForm.projectId = this.$root.proId;
                this.editForm.deviceType = "perception_device";
                const {data: res} = await this.$http4.put(
                    "/updateDeviceName",
                    this.editForm
                );
                if (res.businessCode == "4300") {
                    this.getDeviceList();
                    this.dialogVisibleEdit = false;
                } else {
                    this.$message.error(res.message);
                }
            },
            async deletePerceptionDevice(id) {
                const confirmResult = await this.$confirm(
                    "此操作将永久删除该设备, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).catch((err) => {
                    return err;
                });
                if (confirmResult !== "confirm") {
                    return this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                }
                //请求
                const {data: res} = await this.$http4.delete(
                    "/deleteDevice?deviceId=" + id + "&projectId=" + this.$root.proId
                );
                if (res.businessCode != "5000") {
                    return this.$message.error("删除该设备失败！");
                }
                this.$message.success("删除该设备成功！");
                this.getDeviceList();
            },
            handleClose(done) {
                this.$confirm("确认关闭？")
                    .then((_) => {
                        done();
                    })
                    .catch((_) => {
                    });
            },
            dialogClose() {
                this.$refs.addFormRef.resetFields();
            },
        },
        mounted() {
            this.getAccessToken();
        },

        created() {
            this.getDeviceList();
        },
        components: {
            commonleft,
        },
    };
</script>

<style scoped lang="less">
    .title {
        font-weight: 700;
        margin-bottom: 16px;

        span {
            font-size: 15px;
        }
    }

    .content {
        width: 96.35%;
        margin: 0 auto;
        -webkit-box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
        box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
        border: none;
        border-collapse: collapse;
        margin-top: 20px;
    }

    /deep/ .el-card__body {
        padding: 20px;
    }

    .divright {
        /deep/ .el-card__body {
            padding: 0;
        }
    }

    .areaTree {
        width: 40%;
        height: 570px;
    }

    .areaTable {
        width: 100%;
        height: 570px;
    }

    // .eld1{
    //   position: relative;
    //   top: 0px;
    // }
    // .eld1 .block{
    //   position: absolute;

    //   right: 5px;
    // }
</style>
