import { render, staticRenderFns } from "./wordCreate.vue?vue&type=template&id=6aefd090&scoped=true"
import script from "./wordCreate.vue?vue&type=script&lang=js"
export * from "./wordCreate.vue?vue&type=script&lang=js"
import style0 from "./wordCreate.vue?vue&type=style&index=0&id=6aefd090&prod&lang=less"
import style1 from "./wordCreate.vue?vue&type=style&index=1&id=6aefd090&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aefd090",
  null
  
)

export default component.exports