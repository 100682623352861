<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <div id="cesiumContainer">
            <div class="box-card">
            <el-card >
                <div slot="header" class="clearfix">
                    <span>项目基本信息</span>
                </div>
                <div  class="text item" style="">
                    <h2 style="color:black;text-align: center;margin-top: -7px ">{{pro.projectName}}</h2>
                    <p style="margin-top: 5px"> 项目地址：{{pro.projectAddress}}<br>
                        项目类型：项目为{{pro.projectType.typeName}}类项目<br>
                        项目规模：项目建设高度为：{{pro.buildingHeight}}米 -- 项目建设总面积为:{{pro.buildingArea}}平方米<br>
                        投资规模：项目总投资共计{{pro.totalInvestment}}亿元人民币<br>
                        建设期限：{{pro.planStartDate}}至 {{pro.planEndDate}}<br>
                        业主单位：{{pro.ownerUnit}}<br>
                        总包单位：{{pro.epcUnit}}<br>
                        项目现状：{{pro.projectStatus}}
                    </p>
                </div>
            </el-card>
            </div>
            <div id="bimshow" v-show="bimormap">

            </div>
            <div id="mapshow" v-show="!bimormap">

            </div>
            <div class="qiehuan">
                        <div class="toolsbtn"  style="background-color: #1E50ae; width: 45%;height: 80%;text-align:center; border-radius: 15px  0 0 15px;border: 2px solid #1E50ae;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current2==1}" @click="bim">BIM</div>
                        <div class="toolsbtn" style="background-color: #061840; width: 45%;height: 80%;text-align:center; border-radius: 0px  15px 15px 0px;border: 2px solid #061840;color: #a6abb3;float: left;font-size: 15px;line-height: 170%;" :class="{activie:current2==2}" @click="map">地图</div>
            </div>
            <div  class="box-card1">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>项目形象</span>
                    </div>
                    <div class="text item">
                        <div class="block">
                            <el-carousel height="310px">
                                <el-carousel-item v-for="item in 4" :key="item">
                                   <img src="../assets/img/pro.jpg">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </el-card>
            </div>
            <div  class="box-card2">
                <el-card id="card2">
                    <div slot="header" class="clearfix">
                        <span>通知公告</span>
                    </div>
                    <div class="text item" >
                        <h3 style="color:#00d7fe;text-align: center;margin-top: 7px ">{{notice.title}}</h3>
                        <p style="margin-top: 5px">    <!-- 1#厂房进度：基础浇筑已全部完成，房心土回填完成，目前正进行钢柱吊装，钢柱吊装进度完成50%<br>
                            2#厂房进度：基础浇筑已全部完成，房心土回填完成，目前正进行钢柱吊装，钢柱吊装进度完成80%<br>
                            3#厂房进度：基础浇筑已全部完成，房心土回填完成，钢柱吊装完成，目前正进行钢梁吊装和屋面檩条安装，钢梁吊装进度50%，屋面檩条安装进度30%<br>
                            4#厂房进度：基础浇筑已全部完成，房心土回填完成，目前正等待钢结构进场<br>
                            5#厂房进度：基础浇筑已全部完成，房心土回填完成，钢柱吊装完成，钢梁吊装完成，檩条安装完成，屋面内板已完成封板，目前正等待消防进场安装<br>
                            6#厂房进度：基础浇筑已全部完成，房心土回填完成，钢柱吊装完成，钢梁吊装完成，檩条安装完成，目前正等待封屋面板<br>
                            食堂进度：基础、梁板柱已浇筑完成，目前正进行墙体砌筑<br>
                            办公楼进度：基础浇筑完成，一层至三层梁板柱浇筑完成，目前正进行屋面板支模和一层墙体砌筑<br>
                            南、东值班室进度：基础已浇筑完成，墙体砌筑完成，目前正在进行顶板支模<br>
                            消防水池进度：基坑开挖已完成，目前正在进行消防水池底板钢筋绑扎<br>-->
                            <el-input  type="textarea" :rows="12"  v-model="notice.description"></el-input>
                        </p>
                    </div>
                    <div class="gonggao">
                        <span :class="{active:current==1}" @click="changeNotice1">1</span>
                        <span :class="{active:current==2}"  @click="changeNotice2">2</span>
                        <span :class="{active:current==3}"  @click="changeNotice3">3</span>
                    </div>
                </el-card>
            </div>
            <div  class="box-card3">
                <el-card>
                    <div slot="header" class="clearfix" style="  border-right:5px solid white;">
                        <span>项目进度</span>
                        <span style="margin-left: 620px">距离项目结束还剩<span style="color: red;font-size: 26px;line-height: 16px">26</span>天</span>
                    </div>
                    <div class="text item">
                        <div class="line-botton">
                            <div
                                    class="line-botton-one"
                                    v-for="progressTime in progressTimeList"
                                    :key="progressTime.seq"
                                    @click="proConClick(progressTime)"
                            >
                                <div class="line-left"></div>
                                <div class="line-middle"></div>
                                <div class="line-left"></div>
                            </div>
                        </div>
                        <!-- 线的数据 -->
                        <div class="data-botton">
                            <div
                                    v-for="progressTime in progressTimeList"
                                    :key="progressTime.id"
                                    :title="progressTime.id"
                            >
                                {{ progressTime.title }}<br />{{
                                progressTime.milestoneDate
                                }}
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
            <div  class="box-card4">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>个人待办</span>
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="320px"
                                :header-cell-style="{background:'#1e50ae',color:'white'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="processName"
                                    label="任务名称"
                                    :show-overflow-tooltip="true"
                                    width="120">
                            </el-table-column>
                            <el-table-column
                                    prop="selfTaskToDealDto.createTime"
                                    label="创建时间"
                                    width="175">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                  >
                                <template v-slot="scope">
                                        <el-tag
                                                type="primary"
                                                @click="daiban"
                                                effect="dark">
                                            办理
                                        </el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
        </div>
        <x-dbottom></x-dbottom>
    </div>
</template>

<script>
    import "../assets/css/ol1.css";
    import "../assets/css/ol2.css";
    import Map from 'ol/Map';
    import View from 'ol/View';
    import TileLayer from 'ol/layer/Tile'
    import * as control from 'ol/control';
    import * as olProj from 'ol/proj';
    import * as olExtent from 'ol/extent';
    import WMTS from 'ol/source/WMTS';
    import WMTSTileGrid from 'ol/tilegrid/WMTS';
    import VectorSource from 'ol/source/Vector';
    import VectorLayer from 'ol/layer/Vector';
    import Style from 'ol/style/Style';
    import GeoJSON from 'ol/format/GeoJSON';
    import Stroke from 'ol/style/Stroke';
    import Text from 'ol/style/Text';
    import Fill from 'ol/style/Fill';
    import Icon from 'ol/style/Icon';
    import Point  from 'ol/geom/Point';
    import Polygon from 'ol/geom/Polygon';
    import Feature from 'ol/Feature';
    var viewer;
    var map;
    var gjSource= new VectorSource();
    var gjLayer = new VectorLayer({
        source: gjSource,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/marker-cheng.png')
            }))
        })
    });
    var createguojiaStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 12px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#111224',
                    width: 2
                }),
                offsetY:25,
                backgroundFill:new Fill({
                    color: '#111224'
                }),
                padding: [1, 3, 0, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: '#0BFADA'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/marker-cheng.png')
            }))
        });
    };
    export default {
        name: "prohome",
        data(){
            return{
                pro:{
                    projectName:'',
                    projectDetails:'',
                    projectAddress:'',
                    projectStatus:'',
                    buildingHeight:'',
                    buildingArea:'',
                    totalInvestment:'',
                    projectType:{
                        typeName:''
                    },
                    ownerUnit:'',
                    epcUnit:'',
                },
                activeIndex2: '',
                current2:2,
                bimormap:false,
                tableData: [],
                config:{
                    header: ['姓名', '部门', '上班','下班'],
                    data: [
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                        ['龙雨欢', '研发', '08:50','20:54'],
                    ]
                },
                progressTimeList:[
                ],
                current:1,
                noticeData:[],
                notice:{
                    title:'',
                    description:'',
                }
            }
        },
        created(){
            this.getProinfo();
            this.getNotice();
            this.getlcList();
            this.getProcess();
        },
        methods:{
            async getProinfo(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findById?id='+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error('查询项目失败！'+res.message)
                }
                this.pro=res.data;
            },
            async getNotice(){
                const {data:res}= await this.$http3.get('/v1.0/activiti/announcement/findAll?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error('查询通知公告失败！'+res.message)
                }
                this.noticeData=res.data;
                this.notice=this.noticeData[0]
            },
            async getlcList(){
                const {data:res}= await this.$http3.get('/v1.0/activiti/process/querySelfTaskByUserId')
                if(res.status!='3000'){
                    return this.$message.error('查询待办列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getProcess(){
                const {data:res}= await this.$http6.get('/v1.0/schedule/milestone/findAll?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.progressTimeList=res.data;
            },
            daiban(){
                window.sessionStorage.setItem("current",11)
                this.$router.push({ path: "/todo" });
            },
            changeNotice1(){
              this.current=1;
              this.notice=this.noticeData[0]
            },
            changeNotice2(){
                this.current=2;
                this.notice=this.noticeData[1]
            },
            changeNotice3(){
                this.current=3;
                this.notice=this.noticeData[2]
            },
            async sanwei(){
                var obj=[6378137.0, 6378137.0,6356752.3142451793];
               Cesium.Ellipsoid.WGS84=Object.freeze(new Cesium.Ellipsoid(obj[0],obj[1],obj[2]))
                viewer = new Cesium.Viewer("bimshow",{
                    infoBox : false,
                    selectionIndicator : false,
                });
                viewer._cesiumWidget._creditContainer.style.display = "none";
                viewer.scene.globe.depthTestAgainstTerrain = false
                var tileset=viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
                    url:'http://localhost:8086/sanwei/b3d/tileset.json'
                }))
                tileset.readyPromise.then(function (tileset) {
                    console.log(tileset)
                   var boundingSphere=tileset.boundingSphere;
                    viewer.camera.viewBoundingSphere(boundingSphere,new Cesium.HeadingPitchRange(0.0,-0.5,boundingSphere.radius))
                    viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
                    /*viewer.zoomTo(
                        tileset,
                        new Cesium.HeadingPitchRange(
                            0.5,
                            -0.2,
                            tileset.boundingSphere.radius * 4.0
                        )
                    );*/
                }).otherwise(function (err) {
                    throw(err)
                })
            },
            async showmap(){
                map = new Map({
                    target: 'mapshow',
                    view: new View({
                        center: [85.28838135,42.209817352],
                        zoom: 6,
                        projection: 'EPSG:4326'
                    }),
                });
                var projection = olProj.get('EPSG:4326');
                var projectionExtent = projection.getExtent();
                var size = olExtent.getWidth(projectionExtent) / 256;
                var resolutions = new Array(19);
                var matrixIds = new Array(19);
                for(var z = 0; z < 19; z++) {
                    // generate resolutions and matrixIds arrays for this WMTS
                    //resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = z;
                }
                resolutions = [1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 0.0006866455078125, 0.00034332275390625, 0.000171661376953125,
                    0.0000858306884765625, 0.00004291534423828125, 0.000021457672119140625, 0.000010728836059570312, 0.000005364418029785156, 0.000002682209014892578, 0.000001341104507446289
                ];
                var webKey = "b0f80d188ee3100e0327398d8afb39cd";
                var tdtsldtLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=" + webKey,
                        layer: 'vec',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtslzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=" + webKey,
                        layer: 'cva',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=" + webKey,
                        layer: 'cia',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/img_c/wmts?tk=" + webKey,
                        layer: 'img',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                map.addLayer(tdtsldtLayer);
                map.addLayer(tdtslzjLayer);
                var xjSource = new VectorSource({
                    url: 'http://localhost:8086/xj.json',
                    format: new GeoJSON(),
                    wrapX: false
                });
                var xjLayer = new VectorLayer({
                    source: xjSource,
                    style: new Style({
                        stroke: new Stroke({
                            //color: '#0033ff',
                            color: 'rgb(0, 186 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 186 ,255, 0.01)'
                        })
                    })
                });
                var proSource = new VectorSource({
                    wrapX: false
                });
                var proLayer = new VectorLayer({
                    source: proSource,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'rgb(222, 126 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(122, 126 ,155, 0.3)'
                        })
                    })
                });
                var gjFeature = new Feature({
                    geometry: new Polygon([[[76.15,39],[76.85,39.95],[77.49,39.81],[77.55,38.79],[76.15,39]]]), //几何信息
                });
                proSource.addFeature(gjFeature)
                map.addLayer(xjLayer)
                map.addLayer(proLayer)
                var gjFeature = new Feature({
                    geometry: new Point([Number(77),Number(39.5)]), //几何信息
                    name:'图木舒克市农副产品精深加工标准化厂房项目（EPC）'
                });
                map.getView().setCenter([77,39.5])
                gjFeature.setStyle(createguojiaStyle(gjFeature));
                gjSource.addFeature(gjFeature)
                map.addLayer(gjLayer)

            },
            backmanage(){
                this.$router.push({ path: "/backmanage" });
            },
            proConClick(list){
               /* return new Promise((resolve, reject) => {
                    this.contractPament = progressTimeList
                    this.contractTermList = progressTimeList.contractTermList;
                });*/
            },
            projectClick(project) {
                return new Promise((resolve, reject) => {
                    this.progressTimeList = [];
                    var self = this;
                    for (let i = 0; i < project.progressList.length; i++) {
                        var progressTimeList = project.progressList[i];
                        self.progressTimeList.push(progressTimeList);
                    }
                    self.contractTermList = self.progressTimeList[0].contractTermList;
                    self.contractPament = self.progressTimeList[0]//点击右侧按钮后展示框显示的默认值
                });
            },
            bim(){
                this.current2=1;
                this.bimormap=true
            },
            map(){
                this.current2=2;
                this.bimormap=false;
            }
        },
        mounted() {
            this.sanwei()
            this.showmap()
        }

    }
</script>

<style scoped lang="less">
    .bg{
        background-color: white;
        height: 100vh;
    }
    #cesiumContainer {
        //position: absolute;
        top: 80px;
        left: 0;
        height:800px;
        width: 100%;
        margin: 0;
        overflow: hidden;
        padding: 0;
        font-family: sans-serif;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #bimshow{
        width: 930px;
        height: 570px;
        margin-top: 10px;
        border: 1px solid #2862b7;
    }
    #mapshow{
        width: 930px;
        height: 570px;
        margin-top: 10px;
        border: 1px solid #2862b7;
    }
    .qiehuan{
        position: absolute;
        margin-left: 500px;
        margin-top: 545px;
        z-index: 999;
        width: 90px;
        height:35px;
    }
    .box-card{
        border: 1px solid #1e50ae;
        margin-top: 10px;
        margin-left: 10px;
        width: 470px;
        height: 388px;
    }
    .box-card1{
        border: 1px solid #1e50ae;
          margin-top: 10px;
          width: 470px;
          height: 388px;
        margin-right: 10px;
      }
    .box-card2{
        border: 1px solid #1e50ae;
        margin-top: -175px;
        width: 470px;
        margin-left: 10px;
        height: 390px;
    }
    .box-card3{
        border: 1px solid #1e50ae;
        margin-top: 8px;
        width: 932px;
        height: 207px;
    }
    .box-card4{
        border: 1px solid #1e50ae;
        margin-top: -175px;
        margin-right: 10px;
        width: 470px;
        height: 390px;
    }

    .el-carousel__item img {
       width: 100%;
        height: 100%;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .yingdao{
        float: left;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #268DF6;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }

    }
    .shidao{
        width: 120px;
        height: 65px;
        float: left;
        margin-left: 35px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .queqin{
        width: 120px;
        height: 65px;
        margin-left: 310px;
        border-radius: 5px;
        background-color: #DE494D;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    #card2{
        /deep/ .el-card__body{
            padding-top: 10px;
        }
    }
    .line-botton {
             width: 850px;
             height: 20px;
             margin: 40px 10px 0 10px;
             // background: #000;
             display: flex;
             justify-content: center;
             align-items: center;
             .line-botton-one {
                 width: 20%;
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 .line-left {
                     width: 48%;
                     border-bottom: 5px solid rgb(30, 80, 174);
                     margin-top: 15px;
                 }
                 .line-middle {
                     cursor: pointer;
                     width: 22px;
                     height: 21px;
                     margin-top: 15px;
                     border-radius: 50%;
                     border: 5px solid #29ecf3;
                     background: rgba(35,210,216,.5)
                 }
             }
         }
    .data-botton {
        width: 850px;
        height: 20px;
        margin: 28px 0px 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            width: 162px;
            height: 40px;
            margin: 0 13px;
            text-align: center;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .gonggao{
        background: rgba(20,34,69,.2);
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin: 0 10px;
            background: #949494;
            border-radius: 50%;
            cursor: pointer;
        }
        .active{
            background: #365dba;
            color: #fff;
        }
    }
    .toolsbtn:hover{
        cursor:pointer;
    }
    .activie{
        border: 2px solid #29ecf3 !important;
        background-color: #29ecf3 !important;
        color: #a6abb3 !important;
    }
    /deep/ .el-tag{
        cursor: pointer;
    }
</style>

