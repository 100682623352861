import { render, staticRenderFns } from "./kaoList.vue?vue&type=template&id=ebb58a82&scoped=true"
import script from "./kaoList.vue?vue&type=script&lang=js"
export * from "./kaoList.vue?vue&type=script&lang=js"
import style0 from "./kaoList.vue?vue&type=style&index=0&id=ebb58a82&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebb58a82",
  null
  
)

export default component.exports